<template>
  <div>
    <div class="top">
      <topToolBar ref="topToolBar" />
    </div>
    <div :class="['main-con', left ? '' : 'noleft']">
      <div class="away" @click="putAway()"><i class="el-icon-d-arrow-left"></i></div>
      <div class="left">
        <LeftToolBar
          ref="leftToolBar"
          :svg-info-data="svgInfoData"
          :select-svg-info="selectSvgInfo"
          :tool-type-array="toolTypeArray"
        />
      </div>
      <div
        id="con"
        ref="con"
        class="con"
        :style="{
          width: ' calc(100vw - 565px - ' + box.right + 'px + ' + (left ? 0 : 280) + 'px)'
        }"
      >
        <ruler ref="ruler" :box="box" />
        <div class="svg-box">
          <baseSvg
            ref="baseSvg"
            :box="box"
            :bg-color="bgColor"
            :svg-info-data="svgInfoData"
            :select-svg-info="selectSvgInfo"
            :tool-type-array="toolTypeArray"
          />
        </div>
      </div>
      <div class="tool">
        <div style="font-weight:bold">画布</div>
        <el-divider direction="vertical"></el-divider>
        <span>宽度：</span>
        <el-input-number
          v-model="box.width"
          size="mini"
          style="width:110px"
          controls-position="right"
          :min="10"
        ></el-input-number>
        <span>高度：</span>
        <el-input-number
          v-model="box.height"
          size="mini"
          style="width:110px"
          controls-position="right"
          :min="10"
        ></el-input-number>
        <span>比例：</span>
        <el-popover v-model="visible" placement="top-start" width="70" trigger="hover">
          <div>
            <li v-for="item in zoomOption" :key="item" class="zoomOption" @click="changeZoom(item)">
              {{ item }}%
            </li>
          </div>
          <el-input-number
            slot="reference"
            v-model="boxz"
            style="width:120px;"
            :min="1"
            :max="1000"
            :step="5"
            label="描述文字"
            @change="changeBoxz(true)"
            size="mini"
          ></el-input-number>
        </el-popover>

        <div v-if="isGroupCollect" class="group-collect">
          <span style="margin-top: 2px;">
            <el-radio-group v-model="radio" @input="isShowChange">
              <el-radio :label="1">显示采集点</el-radio>
              <el-radio :label="2">单击设备可见性</el-radio>
            </el-radio-group>
          </span>

          <span
            :class="pointChecked ? 'point-checked active' : 'point-checked'"
            @click="handleCheckedCitiesChange(!pointChecked)"
          >
            <img class="img-cursor" :src="pointChecked ? showIcon : hiddenIcon" />
            <span>采集点</span>
          </span>
        </div>
        <!-- <span>显示采集点名：</span>
        <img
          v-if="pointNameChecked"
          class="img-cursor"
          :src="showIcon"
          @click="pointNameChange(false)"
        />
        <img v-else class="img-cursor" :src="hiddenIcon" @click="pointNameChange(true)" /> -->
      </div>
      <div class="right" :style="{ right: box.right + 'px' }">
        <RightToolBar
          :select-svg-info="selectSvgInfo"
          :tool-type-array="toolTypeArray"
          :box-right="box.right"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ruler from './baseEl/ruler.vue'
import baseSvg from './baseEl/svg/index.vue'
import LeftToolBar from './baseEl/svg/LeftToolBar.vue'
import RightToolBar from './baseEl/svg/RightToolBar.vue'
import topToolBar from './baseEl/svg/topToolBar.vue'

export default {
  components: { ruler, baseSvg, LeftToolBar, RightToolBar, topToolBar },
  data() {
    return {
      bgColor: '',
      pointChecked: true,
      pointNameChecked: true,
      isGroupCollect: false,
      zoomOption: [800, 400, 200, 150, 100, 50, 25],
      visible: false,
      toolTypeArray: [
        'textbox', //文本框
        'lineSegment', //线段
        'pipeline',
        'pipeline_v',
        'pipeline_h', //管道-斜向、横向、纵向
        'totalTiredness', //关联采集点-总累标况
        'conditioning', //关联采集点-瞬时标况
        'pressureCollection', //关联采集点-压力采集
        'concentrationCollection', //关联采集点-浓度采集
        'temperatureCollection', //关联采集点-温度采集
        'totalTiredConditions', //关联采集点-总累工况
        'instantaneousService', //关联采集点-瞬时工况
        'module', // 组、撬单独拖拽采集点
        'skid', // 组、撬单独拖拽采集点
        'collectPoint' // 组、撬单独拖拽采集点
      ],
      svgInfoData: require('./svgData/type.json'),
      /* json文件里写注释一直报错，先写在这些
      { "type": "元件类型，具有唯一性",
        "title": "元件类型中文",
        "identity": "元件标识，有可能不唯一，跟uuid组成元件id，例子11，12，21，22",
        "panel_class": "draggable||draw",//这个应该时没用的
        "template": "<fragment><line1/><line2/></fragment>",//元件svg模板，多个元素时外面包一层fragment
        "props": ["prop_data"],//这样写就行
        "extend_attr": {//扩展数据，显示个性化的元件属性
          "width": 30,//元件宽度的一半，旋转会用到
          "height": 8.5,//元件高度的一半，旋转会用到
          "svg_color": {
            "title": "颜色",//显示在右侧的属性名称
            "val": "#0053b9",//显示在右侧的属性值
            "type": "colorinputbox"//元件属性类型，
            颜色选取框colorinputbox，
            文本输入框textinputbox，
            数字框numberinputbox，
            选择框select，
            压力级别选择框，不同压力级别管道颜色不同select_plevel，
            从接口获取数据的选择框select_Interface
          }
        },
        "data_type": "baseTool",//左侧工具栏类别，data_type相同的在一个collapse下
        "create_type": "draggable",//绘制元件方式draggable是拖拽，click是选中绘制
        "priview_img": "/static/svg/arrowhead.svg"
      },
      */
      selectSvgInfo: {
        title: '',
        name: '',
        svgPositionX: '',
        svgPositionY: '',
        sizeW: '',
        sizeH: '',
        angle: '',
        extend_attr: {}
      },
      box: {
        right: -280,
        width: 1600,
        height: 760,
        background: 'fff'
      },
      boxz: 100,
      left: true,
      showIcon: require('@/assets/image/svg/showIcon.png'),
      hiddenIcon: require('@/assets/image/svg/hiddenIcon.png'),
      radio: 1
    }
  },
  computed: {},
  watch: {
    'selectSvgInfo.id'(val) {
      if (val) {
      } else {
        this.box.right = -280
        this.isGroupCollect = false
      }
    }
  },
  mounted() {
    //  this.box.height= this.$refs.con.scrollHeight
    //  this.box.width= this.$refs.con.scrollWidth
    //  console.log(this.box)
  },
  beforeDestroy: function() {},
  methods: {
    /**
     * 单选框radio
     * 1显示采集点：一直显示采集点
     * 2单击设备可见性：在展示页面，鼠标点击设备图标来显示、隐藏采集点信息
     * 选中“单击设备可见性”的时候，后面的"显示采集点"生效，此属性是展示页面初始化的时候，默认显示、隐藏采集点
     */
    isShowChange(val) {
      let id = this.$refs.baseSvg.selectSvg.ID
      if (id && id.substr(0, 2) == '99') {
        this.$refs.baseSvg.svgLists.forEach(element => {
          console.log(element, id)
          if (element.id == id) {
            element.extend_attr.clickPointChecked = val
          }
        })
      }
    },
    //松开鼠标mouseup时，显示选中元件信息，隐藏方法用上面的watch 'selectSvgInfo.id'
    showRightToolBar() {
      if (this.selectSvgInfo.id) {
        this.box.right = 0

        // 是否展示底部采集点设置
        let id = this.$refs.baseSvg.selectSvg.ID
        if (id && id.substr(0, 2) == '99') {
          console.log(this.selectSvgInfo)
          this.radio = this.selectSvgInfo.extend_attr.clickPointChecked
          this.pointChecked = this.selectSvgInfo.extend_attr.pointChecked
          this.isGroupCollect = true
        } else {
          this.isGroupCollect = false
        }
      }
    },
    changeZoom(val) {
      this.boxz = val
      this.$refs.baseSvg.zoomChange(val / 100)
      this.visible = false
    },
    putAway() {
      this.left = !this.left
    },
    setBoxz(zoom) {
      let n = Number(100 * zoom)
      this.boxz = Math.ceil(n)
    },
    changeBoxz(type) {
      if (type) {
        this.$refs.baseSvg.zoomChange(this.boxz / 100)
      }
    },
    handleCheckedCitiesChange(status) {
      let id = this.$refs.baseSvg.selectSvg.ID
      if (id && id.substr(0, 2) == '99') {
        this.$refs.baseSvg.svgLists.forEach(element => {
          if (element.id == id) {
            element.extend_attr.pointChecked = status
          }
        })
      }
      this.pointChecked = status
      // this.$refs.baseSvg.pointChecked = status
    },
    pointNameChange(status) {
      this.pointNameChecked = status
      this.$refs.baseSvg.pointNameChecked = status
    },
    /**
     * 获取文本宽度
     * @param {*} text 文本内容
     * @param {*} size 文字大小
     * @param {*} weight 加粗:正常400，加粗700，字体默认微软雅黑
     * @return 保留2位小数
     */
    getActualWidthOfChars(text, size, weight) {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      ctx.font = `normal ${weight} ${size}px Microsoft YaHei`
      const metrics = ctx.measureText(text)
      const actual =
        Math.abs(metrics.actualBoundingBoxLeft) + Math.abs(metrics.actualBoundingBoxRight)
      return Number(Math.max(metrics.width, actual).toFixed(2))
    },
    /**
     * 获取文本高度
     * @param text 文本内容
     * @param size 文字大小,字体就默认微软雅黑，是否加粗没有影响
     * @return 保留2位小数
     */
    getActualHeightOfChars(text, size) {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      ctx.font = `${size}px Microsoft YaHei`
      const metrics = ctx.measureText(text)
      let fontHeight = metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent
      return fontHeight
    }
  }
}
</script>

<style lang="scss" scoped>
.showPoint {
  /* position: absolute;
  right: 32px;
  top: 42px;
  z-index: 100; */
  width: 114px;
  height: 34px;
  background: var(--background-top-color-skin);
  box-shadow: 0px 0px 5px 1px rgba(194, 196, 205, 0.51);
  border-radius: 2px;
  line-height: 34px;
  text-align: center;
  :deep() .el-checkbox__label {
    font-size: 14px;
  }
}
.zoomOption {
  line-height: 34px;
  padding-left: 20px;
  cursor: pointer;
}
.zoomOption:hover {
  background: #f8f8f8;
}
.tool {
  width: auto;
  margin-left: 280px;
  display: flex;
  padding:8px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* #e1e1e1 */
  color: var(--background-top-content-skin);
  border-top: 1px solid var(--background-top-title-skin);
  background-color: var(--background-top-main-skin);
  span {
    padding-left: 10px;
  }
}

.top {
  height: 54px;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.08);
}
.away {
  color: var(--background-top-font-skin);
  position: absolute;
  padding: 4px;
  left: 245px;
  top: 4px;
  cursor: pointer;
  z-index: 9;
}
.noleft .away {
  left: 10px;
  transform: rotate(180deg);
}
.main-con.noleft {
  .left {
    margin-left: -280px;
  }
  .con {
    margin-left: 10px;
  }
}
:deep() {
  .el-form-item__label {
    color: var(--background-top-contentFont-skin);
  }
  .el-input__inner {
    background-color: var(--background-top-input-skin);
    border: 1px solid var(--background-top-inputBorder-skin);
    color: var(--background-top-contentFont-skin);
  }
  .el-input.is-disabled .el-input__inner {
    background-color: var(--background-top-header-skin);
    border: 1px solid var(--background-top-inputBorder-skin);
  }
  .el-divider {
    background-color: var(--background-top-border);
  }
  /* .el-input-number__increase, .el-input-number__decrease{
    background:var(--background-top-inputBorder-skin)
  } */
}
:deep() .left-main {
  height: calc(100vh - 90px);
  overflow: auto;
  background: var(--background-top-main-skin);
}
.main-con {
  position: relative;
  .left {
    width: 280px;

    float: left;
    box-shadow: 1px 0px 8px 0px rgba(0, 0, 0, 0.08);
  }
  .right {
    width: 285px;
    height: calc(100vh - 100px);
    position: absolute;
    padding-bottom: 60px;
    overflow: auto;
    top: 0;
    right: 0;
    box-shadow: 1px 0px 8px 0px rgba(0, 0, 0, 0.08);
    background: var(--background-top-main-skin);
    color: var(--background-top-font-skin);
  }
  .con {
    margin-left: 280px;
    margin-right: 285px;
    background: var(--background-top-svg-skin);
    height: calc(100vh - 170px);
    overflow: auto;
    position: relative;
  }
  .svg-box {
    margin-top: 20px;
    margin-left: 22px;
  }
}
.group-collect {
  display: flex;
}
.point-checked {
  line-height: 16px;
  color: #606266;
  display: flex;
  align-items: center;
  cursor: pointer;
  .img-cursor {
    margin-left: 25px;
  }
}
.point-checked.active {
  color: #1082ff;
}
</style>
