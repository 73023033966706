<template>
  <div>
    <div class="collapseDiv" @click="changeLeftShow">
      工具栏
      <!-- <i :class="leftShow ? 'el-icon-d-arrow-left' : 'el-icon-d-arrow-right'"></i> -->
    </div>
    <div class="left-main">
      <el-collapse v-model="activeNames" class="collapseClass">
        <el-collapse-item
          v-for="(pItem, pIndex) in toolList"
          :key="pIndex"
          :title="pItem.title"
          :name="pItem.name"
        >
          <ul class="svg-nav-list">
            <li v-for="(item, index) in pItem.list" :key="index">
              <div
                :class="setActive(item.type) ? 'title-img active' : 'title-img'"
                draggable="draggable"
                @mousedown="
                  Mousedown(
                    item.type,
                    item.title,
                    item.identity,
                    item.default_attr,
                    item.create_type,
                    item.extend_attr
                  )
                "
              >
                <img :src="item.priview_img" :class="roteImg(item.title)" />
                <div class="title">{{ item.title }}</div>
              </div>
            </li>
          </ul>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>
<script>
export default {
  props: ['svgInfoData', 'toolTypeArray'],
  data() {
    return {
      leftShow: true,
      baseToolShow: true,
      activeNames: [],
      toolList: [], //左侧工具栏列表
      collectExtendAttr: {}, //采集点信息
      draggableComponentList: [], //拖动组件
      drawComponentList: [], //绘制类型组件
      chartComponentList: [], //图表类型
      toolTabType: 'module'
    }
  },
  watch: {
    svgInfoData(val) {
      this.initData()
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    /**
     * @description: 初始化图标
     * @param: val工具栏标识
     */
    initData() {
      let val = this.svgInfoData

      let baseToolList = val.filter(m => {
        return m.data_type == 'baseTool'
      })
      let pipeLineList = val.filter(m => {
        return m.data_type == 'pipeLine'
      })
      let equipmentList = val.filter(m => {
        return m.data_type == 'equipment'
      })
      let accessoryEQList = val.filter(m => {
        return m.data_type == 'accessoryEQ'
      })
      let otherEQList = val.filter(m => {
        return m.data_type == 'otherEQ'
      })

      let collectionPointList = val.filter(m => {
        return m.data_type == 'collectionPoint'
      })

      this.collectExtendAttr = val.filter(m => {
        return m.data_type == 'collectionPoint2'
      })[0].extend_attr

      // 默认展开的工具栏
      this.activeNames = [
        'baseTool',
        'pipeLine',
        'equipment',
        'accessoryEQ',
        'otherEQ',
        'collectionPoint'
      ]
      this.toolList = [
        { title: '基础工具', name: 'baseTool', list: baseToolList },
        { title: '管道', name: 'pipeLine', list: pipeLineList },
        { title: '设备', name: 'equipment', list: equipmentList },
        { title: '附属设备', name: 'accessoryEQ', list: accessoryEQList },
        { title: '其他设备', name: 'otherEQ', list: otherEQList }
        // { title: '关联采集点', name: 'collectionPoint', list: collectionPointList }
      ]
      let module = val.filter(m => {
        return m.data_type == 'module'
      })

    },
    roteImg(name) {
      if (name == '放散装置' || name == '三通') {
        return 'roteImg'
      } else {
        return ''
      }
    },

    /**
     * @description: 展开/收起某类工具
     * @param: val工具栏标识
     */
    changeLeftShow() {
      this.leftShow = !this.leftShow
    },
    /**
     * @description: 点击左侧工具栏触发函数
     * @param {*} type
     * @param {*} title
     * @param {*} default_attr 属性默认值
     * @param {*} create_type 组件创建方式
     * @return {*}
     */
    Mousedown(type, title, identity, default_attr, create_type, extend_attr) {
      if (type == 'collectPoint') {
        console.log(extend_attr)
        let arr = this.collectExtendAttr
        arr.f_eqtype.val = extend_attr.groupName
        arr.v_eqname.val = extend_attr.pointName
        arr.v_eqid.val = extend_attr.pointId
        arr.f_eqvalue.val = extend_attr.currVal + extend_attr.dataUnit
        arr.f_valuesize.val = this.getStrLength(extend_attr.pointName) //采集点值长度
        extend_attr = arr
      }
      let CurrentlySelectedToolBar = {
        Type: type, //选中的工具栏svg类型
        TypeName: title, //选中的工具栏svg类型名称
        Title: title, //选中的工具栏svg标题
        Identity: identity, //选中的工具栏svg标题
        //Color: default_attr.color, //选中的工具栏svg颜色
        CreateType: create_type, //选中工具栏的创建方式
        extend_attr: extend_attr ? JSON.parse(JSON.stringify(extend_attr)) : {}
        // EChartsOption: default_attr.echarts_option
      }
      this.$store.commit('zt/setCurrentlySelectedToolBarAction', CurrentlySelectedToolBar)
      //setCurrentlySelectedToolBarAction(CurrentlySelectedToolBar);
    },
    setActive(type) {
      let haves = this.toolTypeArray.indexOf(type)
      if (
        this.$store.getters.CurrentlySelectedToolBar.Type == type &&
        (haves != -1 || type == 'rectSegment')
      ) {
        console.log(6666)

        return true
      } else {
        return false
      }
    },
    /**
     * 获取文本宽度
     * @param {*} text 文本内容
     * @param {*} size 文字大小
     * @param {*} weight 加粗:正常400，加粗700，字体默认微软雅黑
     * @return 保留2位小数
     */
    getActualWidthOfChars(text, size, weight) {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      ctx.font = `normal ${weight} ${size}px Microsoft YaHei`
      const metrics = ctx.measureText(text)
      const actual =
        Math.abs(metrics.actualBoundingBoxLeft) + Math.abs(metrics.actualBoundingBoxRight)
      return Number(Math.max(metrics.width, actual).toFixed(2))
    },
    /**
     * 获取文本高度
     * @param text 文本内容
     * @param size 文字大小,字体就默认微软雅黑，是否加粗没有影响
     * @return 保留2位小数
     */
    getActualHeightOfChars(text, size) {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      ctx.font = `${size}px Microsoft YaHei`
      const metrics = ctx.measureText(text)
      let fontHeight = metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent
      return fontHeight
    },
    getStrLength(str) {
      ///获得字符串实际长度，中文2，英文1
      ///要获得长度的字符串
      var realLength = 0,
        len = str.length,
        charCode = -1
      for (var i = 0; i < len; i++) {
        charCode = str.charCodeAt(i)
        if (charCode >= 0 && charCode <= 128) realLength += 1
        else realLength += 2
      }
      return realLength
    }
  }
}
</script>
<style lang="scss" scoped>
:deep() {
  .el-collapse {
    border-top: 1px solid var(--background-all-btn-color);
    border-bottom: 0px;
  }
  .el-collapse-item__header {
    padding-left: 10px;
    padding-right: 2px;
    height: 36px;
    line-height: 36px;
    font-size: 14px;
    background: var(--background-top-header-skin);
    color: var(--background-top-font-skin);
    box-shadow: 0px 1px 0px 0px rgba(209, 209, 209, 0.45);
    //border-bottom: 1px solid var(--background-top-title-skin);
  }
  .el-collapse-item__content {
    padding-bottom: 10px;
    color: var(--background-top-content-skin);
  }
  .el-collapse-item__wrap {
    background: var(--background-top-main-skin);
    border-bottom: 1px solid var(--background-top-title-skin);
  }
}
.roteImg {
  transform: rotate(90deg);
}
.collapseDiv {
  height: 36px;
  line-height: 36px;
  font-size: 16px;
  padding: 0 10px;
  background: var(--background-top-title-skin);
  color: var(--background-top-font-skin);
  box-shadow: 0px 1px 0px 0px rgba(209, 209, 209, 0.45);
  cursor: pointer;
  i {
    line-height: 36px;
    float: right;
  }
}
.svg-nav-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 5px 4px 0px 4px;
  padding: 0;

  li {
    position: relative;
    width: calc(25% - 8px);
    margin: 2px 4px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .title-img {
      display: grid;
      justify-items: center;
      width: 100%;
    }
    .title-img.active,
    .title-img:hover {
      background: var(--background-top-header-skin);
    }
    img {
      margin-top: 7px;
      display: block;
      width: 26px;
      height: 26px;
    }
    /* img:hover {
      box-shadow: 1px 1px 10px #ccc;
      border: 1px solid #3897ff;
    }
    .active {
      box-shadow: 1px 1px 10px #ccc;
      border: 1px solid #3897ff;
    } */
    .title {
      line-height: 24px;
      font-size: 11px;
      text-align: center;
      white-space: nowrap;
    }
  }
}
.toolbar-selected {
  outline: 1px solid #0cf;
}
.toolTitle {
  display: flex;
  height: 36px;
  line-height: 36px;
  font-size: 14px;
  background: var(--background-top-header-skin);
  color: var(--background-top-font-skin);
  border-bottom: 1px solid var(--background-top-title-skin);
  .titleBtn {
    font-size: 14px;
    font-weight: 400;
    margin: 0 10px;
    height: 36px;
    cursor: pointer;
  }
  .titleBtn.active {
    color: #1082ff;
    font-weight: bold;
    border-bottom: 2px solid #1082ff;
  }
}
.svg-nav-list2 {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 5px 4px 0px 4px;
  padding: 0;
  color: var(--background-top-font-skin);
  li {
    position: relative;
    width: 100%;
    margin: 3px 4px;
    padding: 0;
    display: flex;
    align-items: baseline;
    justify-content: space-around;
    cursor: pointer;
    .nav-img2 {
      transform-origin: center;
      transition: transform 0.5s ease;
    }
    .nav-img2.is-active {
      transform: rotate(90deg);
    }
    .nav-content2 {
      width: calc(100% - 26px);
      border: 1px solid var(--background-top-border);
      background: linear-gradient(
        0deg,
        var(--background-top-inputBorder-skin) 0%,
        var(--background-top-header-skin) 100%
      );
      border-radius: 6px;
      font-size: 12px;
      line-height: 22px;
      padding: 5px 10px;
      word-wrap: break-word;
      word-break: break-all;
      .dragTitle {
        font-weight: bold;
      }
      .dragTitle2 {
        font-weight: bold;
        line-height: 30px;
        .nav-img2 {
          margin-right: 7px;
          transform-origin: center;
          transition: transform 0.5s ease;
        }
        .nav-img2.is-active {
          transform: rotate(90deg);
        }
      }
      .dragItem {
        font-weight: 400;
        margin-left: 15px;
      }
    }
  }
}
</style>
